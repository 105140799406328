
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import VueLottiePlayer from 'vue-lottie-player';

import AtomText from '@/components/atoms/AtomText.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import OrganismShareRideDropdown from '@/components/organisms/home/OrganismShareRideDropdown.vue';

import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';

export default Vue.extend({
  name: 'OrganismSuccessfulCreation.vue',
  props: {
    intents: Array,
  },
  components: {
    AtomText,
    AtomButton,
    // AtomSvgIcon,
    // MoleculeDropdown,
    OrganismShareRideDropdown,
    VueLottiePlayer,
  },
  data: () => ({
    ButtonVariant,
    Features,
  }),
  computed: {
    ...mapState('rideIntent', ['createdRideIntent']),
    hintMessage(): string {
      const possibleMessages = Object.values(this.$t('rideIntent.successScreen.hintMessages'));
      return possibleMessages[`hint${Math.floor(Math.random() * possibleMessages.length)}`];
    },
  },
  methods: {
    ...mapActions('rideIntent', ['resetState', 'shareCreatedRideIntent']),
    isEnvReady,
    complete(): void {
      this.resetState();
      this.$router.push(`/${this.$i18n.locale}/home`);
    },
  },
});
